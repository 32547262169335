<template>
  <div class="form">
    <!-- 工号密码登录表单 -->
    <el-form :model="form" :rules="rules" ref="form" :show-message="true">
      <el-form-item class="company-input">
        <el-autocomplete
          style="width: 100%"
          ref="companyName"
          @change="handleInput"
          v-model="form.companyName"
          :fetch-suggestions="getHistoryCompanyList"
          placeholder="酒店/集团名称"
          @select="chooseCompany"
        >
          <template slot-scope="{ item }">
            <div class="flex-between">
              <span class="name">{{ item.name }}</span>
              <span class="note N4">{{ item.note }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          placeholder="员工工号"
          v-model="form.code"
          ref="code"
          clearable
          maxlength="20"
          autocomplete="new-password"
          @keyup.enter.native="focus('password')"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          placeholder="密码"
          v-model="form.password"
          ref="password"
          type="password"
          show-password
          maxlength="32"
          autocomplete="new-npmpassword"
          @keyup.enter.native="onSubmit"
        />
      </el-form-item>
    </el-form>
    <!-- 登录按钮 -->
    <el-button
      class="button"
      type="primary"
      @click="onSubmit"
      :loading="isLoading"
    >
      {{ isLoading ? "登录中" : "登 录" }}
    </el-button>
    <!-- 忘记密码提示 -->
    <el-tooltip class="item" effect="dark" placement="bottom-end">
      <span class="text-button font14 N3">忘记工号密码?</span>
      <div slot="content">
        请联系您所在酒店/集团的管理员查询工号、重置密码。
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/dataService";
export default {
  data() {
    return {
      isLoading: false, // 是否登录中状态
      form: {
        companyName: "", // 集团名称
        code: "", // 用户工号
        password: "", // 密码
      },
      rules: {},
    };
  },

  mounted() {
    try {
      const companyHistory = JSON.parse(localStorage.companyHistory) || []; // 读取原有的集团历史记录
      if (companyHistory.length > 0) {
        this.form.companyName = companyHistory[0];
      }
    } catch (err) {
      localStorage.companyHistory = [];
    }
  },

  methods: {
    // 自动聚焦输入框
    focus(fieldName) {
      this.$nextTick(() => this.$refs[fieldName].focus());
    },
    // 提交验证
    async onSubmit() {
      if (this.isLoading) return false; // 阻止重复登录
      this.rules = {
        companyName: this.$getFormRule({ name: "集团名称", min: 2 }),
        code: this.$getFormRule({ name: "员工工号" }),
        password: this.$getFormRule({
          name: "密码",
          min: 6,
          minMsg: "密码长度不符合要求",
        }),
      };
      this.$nextTick(() => {
        this.$refs.form.validate(async (valid) => {
          if (!valid) return false;
          this.onPasswordLogin();
        });
      });
    },

    // 工号密码登录
    async onPasswordLogin() {
      const form = JSON.parse(JSON.stringify(this.form));
      const timestamp = new Date().getTime();
      form.password = this.$getRsaCode(timestamp + form.password); // 使用公钥加密密码
      this.isLoading = true;
      try {
        const [res, err] = await this.$post("login", { timestamp, ...form }); // 请求账密登录
        this.isLoading = false;
        if (err) return this.$toast(err);
        const companyHistory = JSON.parse(localStorage.companyHistory || "[]"); // 读取原有的集团历史记录
        companyHistory.unshift(this.form.companyName); // 本次登录的集团排在第一个
        localStorage.companyHistory = JSON.stringify(
          Array.from(new Set(companyHistory))
        ); // 去重后保存新历史记录
        const [user, msg] = await getUserInfo();
        if (msg) return this.$toast(msg);
        this.$emit("ok", user);
      } catch (error) {
        this.isLoading = false;
        localStorage.companyHistory = [];
      }
    },

    // 输入框发生改变触发
    handleInput(e) {
      this.$aegis.reportEvent({
        name: "手动输入集团名称",
        ext1: {
          code: this.form.code,
          companyName: e,
          localName: localStorage.companyHistory,
        },
      });
    },

    // 展示集团名称历史记录
    getHistoryCompanyList(queryString, cb) {
      const companyHistory = JSON.parse(localStorage.companyHistory || "[]"); // 读取原有的集团历史记录
      const list = companyHistory.map((e) => ({ name: e }));
      if (list.length) list[0].note = "上次使用";
      cb(list.filter((e) => e.name.includes(queryString))); // 根据关键词筛选
    },
    // 在历史记录中选择一个集团名称
    chooseCompany(item) {
      this.form.companyName = item.name;
    },
  },
};
</script>

<style scoped>
@import "../../../styles/login-form.scss";
</style>
